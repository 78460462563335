.gallery-section {
    .section-title {
        text-align: center;
        margin: 0 auto 40px;
        max-width: 700px;
    }

    .gallery-img {
        margin-bottom: 20px;
    }
}

.camp-gallery-container {
    margin-top: 50px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .camp-gallery-title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #333;
    }
}

.gallery-carousel {
    max-width: 1000px;
    margin: 0 auto;
    
    .carousel-card {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 15px;
        height: 250px; // Fixed height for all cards

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain; // Ensures images fit properly inside the card
            border-radius: 10px;
        }
    }
}
